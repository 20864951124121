<template>
  <div class="animated fadeIn container">
    <h1>发送情况</h1>
    <table class="border-table mt-4">
      <tr v-if="smsRequest">
        <td style="width: 220px">
          <p>{{ smsRequest.createdAt | dateFormat }}</p>
          <p v-show="summaryInfo.reservationDate != null" style="color: #20a8d8; margin-top: 5px">
            {{ summaryInfo.reservationDate | dateFormat }}
            <button v-show="showCancel" class="btn btn-primary" @click="clickCancelReservation"
              :disabled="isCancelLoading" style="display: block; margin-top: 10px; padding: 3px 10px">
              Cancel
            </button>
          </p>
        </td>
        <td>
          {{ smsRequest.message }}
        </td>
        <td style="width: 280px">
          发送
          <a class="color-skyblue" @click="requestDump('total')" style="cursor: pointer">
            {{ summaryInfo.totalCount }}
          </a>
          / 成功
          <a class="color-skyblue" @click="requestDump('success')" style="cursor: pointer">
            {{ summaryInfo.deliveredCount }}
          </a>
          / 等待
          <a class="color-skyblue" @click="requestDump('wait')" style="cursor: pointer">
            {{ summaryInfo.waitCount }}
          </a>
          / 失败
          <a class="color-skyblue" @click="requestDump('fail')" style="cursor: pointer">
            {{ summaryInfo.deliveryFailedCount }}
          </a>
        </td>
      </tr>
    </table>

    <ul class="recipient-list mt-4">
      <li :key="item.id" v-for="item in items">
        <span @click="copyLink(item.recipient)">{{ item.recipient | phoneFormat }}</span>
        <span class="status-label" v-bind:class="item.class" :title="item.title" @click="clickItem(item)">{{
          item.status
        }}</span>
      </li>
    </ul>
    <grid-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size" style="margin: 20px auto">
    </grid-loader>
    <b-pagination :total-rows="smsRequest.totalCount" v-model="currentPage" :per-page="50" :limit="10" class="my-3">
    </b-pagination>
    <div style="display: flex; gap: 10px; margin-top: 20px">
      <button v-show="this.$store.state.user.level == 9" class="btn btn-primary" @click="clickCancelRequest"
        :disabled="isCancelLoading" style="padding: 5px 10px">
        Cancel by admin
      </button>
      <button v-show="this.$store.state.user.level == 9" class="btn btn-warning" @click="clickResend"
        style="padding: 5px 10px">
        Resend
      </button>
    </div>
  </div>
</template>
<script>
import SmsService from '@/services/SmsService'
import moment from 'moment'
import GridLoader from 'vue-spinner/src/GridLoader.vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'SmsRequest',
  data: () => {
    return {
      isLoading: true,
      isCancelLoading: false,
      items: [],
      limit: 50,
      currentPage: 1,
      smsRequest: {},
      message: null,
      updatedAt: null,
      summaryInfo: {
        totalCount: 0,
        deliveredCount: 0,
        deliveryFailedCount: 0,
      },
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
      showCancel: false,
    }
  },
  components: {
    GridLoader,
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')

    this.getRequest()
  },
  methods: {
    clickCancelRequest() {
      if (!confirm('Are you sure want to cancel this request?')) {
        return
      }

      var t = this
      t.isCancelLoading = true
      SmsService.cancelRequest(this.$route.params.id)
        .then(() => {
          t.$router.push({
            path: '/SmsRequests',
          })
        })
        .catch(function () {
          t.isCancelLoading = false
        })
    },
    clickCancelReservation() {
      if (!confirm('Are you sure want to cancel this reservation?')) {
        return
      }

      var t = this
      t.isCancelLoading = true
      SmsService.cancelReservation(this.$route.params.id)
        .then(() => {
          t.$router.push({
            path: '/SmsRequests',
          })
        })
        .catch(function () {
          t.isCancelLoading = false
        })
    },
    async clickResend() {
      if (!confirm('Are you sure want to resend this reservation?')) {
        return
      }

      const response = await SmsService.resend(this.$route.params.id)
      if (response.status === 200) {
        alert('success')
      }
    },
    clickItem(item) {
      if (item.smsID) {
        alert(item.smsID)
      }
    },
    requestDump(type) {
      this.$router.push('/dumps?requestId=' + this.smsRequest.id + '&type=' + type)
    },
    isRecent(createdAt) {
      if (!createdAt || createdAt.length == 0) {
        return
      }

      var date = moment().add(-30, 'minutes')
      return date.isBefore(moment(String(createdAt)))
    },
    async getRequest() {
      const response = await SmsService.getRequest(this.$route.params.id)

      if (response.status === 200 && response.data) {
        this.smsRequest = response.data.smsRequest
        this.summaryInfo = response.data.summaryInfo
        if (this.smsRequest.unit) {
          this.getRequestUnit()
        }

        if (this.summaryInfo.reservationDate != null) {
          this.showCancel = moment().isBefore(moment(this.summaryInfo.reservationDate))
        }
      }
    },
    paidMoney: function (count) {
      return parseFloat(count) * 19.8
    },
    async getRequestUnit() {
      var unit = this.smsRequest.unit[this.currentPage - 1]
      this.isLoading = true
      const response = await SmsService.getRequestUnit(this.smsRequest.id, unit.id)
      this.isLoading = false

      var list = response.data.list || []
      var classDic = {
        成功: 'success',
        失败: 'danger',
        等待: 'warning',
      }
      var items = []
      for (var i = 0; i < list.length; i++) {
        var item = list[i]
        items.push({
          recipient: item.recipient,
          status: item.status,
          class: classDic[item.status],
          title: item.status,
          smsID: item.smsID,
        })
      }

      this.items = items
    },
    copyLink: function (recipient) {
      if (this.user.level < 9) {
        return
      }
      const t = document.createElement('textarea')
      document.body.appendChild(t)
      t.value = Vue.filter('phoneFormat')(recipient)
      t.select()
      document.execCommand('copy')
      document.body.removeChild(t)
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    currentPage: function () {
      this.getRequestUnit()
    },
    isCancelLoading() { },
  },
}
</script>
<style scoped>
p {
  margin: 0;
}

.link {
  cursor: pointer;
}

.border-table tr {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.recipient-list {
  width: 100%;
  display: block;
  overflow: hidden;
}

.recipient-list>li {
  position: relative;
  float: left;
  margin: 5px 0.5%;
  width: 19%;
  height: 53px;
  border: 1px solid #cccccc;
  padding: 15px 12px;
  border-radius: 3px;
}

.recipient-list>li>.status-label {
  position: absolute;
  top: 15px;
  right: 12px;
  padding: 3px 10px;
  font-size: 10px;
  border-radius: 2px;
  color: white;
}

.recipient-list>li>.success {
  background-color: #6cba7b;
}

.recipient-list>li>.danger {
  background-color: #e77470;
}

.recipient-list>li>.warning {
  background-color: #f6c244;
}
</style>
